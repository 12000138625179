<template>
    <div style="background: #EDEDED;">
      <van-image  :src="require('@/assets/img/hj.png')" class="bg"></van-image>

      <div class="departments" v-for="item in dataList" :key="item.value">
            <div class="department-title">{{item.label}}</div>
            <div class="department-item" v-for="it in item.list" :key="it.id">
                <div class="item-name">{{it.name}}</div>
                <div class="item-phone">
                    <div class="phone">{{it.mobile}}</div>
                    <a  @click.stop="" :href="'tel:'+it.mobile"><img src="@/assets/img/phone2.png" alt="" class="phone-icon"/></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataList: []
            }
        },
        mounted() {
          if (this.$userId && this.$userId != 'undefined') {
            this.getHouseList()
          } else {
            this.$httpCustApp({
              url: this.$httpCustApp.adornUrl('/wxapp/appconveniencephonebook/nickList'),
              method: 'get',
              params: this.$httpCustApp.adornParams({
                page: 1,
                limit: -1,
                orgId: this.$orgId
              })
            }).then(({data}) => {
              if(data && data.code === 0) {
                this.getTypeList(data.page.list)
              }
            })
          }
        },
        methods: {
            getHouseList() {
                this.$http({
                    url: this.$http.adornUrl('/wxapp/building/house/list'),
                    mothod: 'post',
                    params: this.$http.adornParams({
                        page: 1,
                        limit: -1,
                        userId: this.$globalData.userInfo.userId,
                        orgId: this.$cookies.get('orgId'),
                        loginUserId: this.$globalData.userInfo.userId,
                        loginUserOrgId: this.$globalData.userInfo.orgId
                    })
                }).then(({data}) => {
                    if(data && data.code === 0) {
                        let subareaIds =  data.page.list.map(item => {
                            return item.subarea
                        })
                        this.getDataList(subareaIds)
                    }
                })
            },
            getDataList(subareaIds) {
                this.$httpCustApp({
                    url: this.$httpCustApp.adornUrl('/wxapp/appconveniencephonebook/list'),
                    method: 'get',
                    params: this.$httpCustApp.adornParams({
                        page: 1,
                        limit: -1,
                        subareas: subareaIds.toString(),
                        loginUserId: this.$globalData.userInfo.userId,
                        loginUserOrgId: this.$globalData.userInfo.orgId
                    })
                }).then(({data}) => {
                    if(data && data.code === 0) {
                        this.getTypeList(data.page.list)
                    }
                })
            },
            getTypeList(list) {
                this.$http({
                    url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
                    method: 'get',
                    params: this.$http.adornParams({
                        orgId: this.$cookies.get('orgId'),
                        code: 'phoneBook'
                    })
                }).then(({data}) => {
                    if(data && data.code === 0) {
                        data.dicts.forEach(item => {
                            item.list = []
                            list.forEach(it => {
                                if(item.value === it.type) {
                                    item.list.push(it)
                                }
                            })
                        })
                        this.dataList = data.dicts
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .departments {
        width: 100vw;
        background: #FFFFFF;
        padding: 14px 30px 0 30px;
        margin-top: 12px;
        &:first-child {
            margin-top: 0px;
        }
        .department-title {
            height: 90px;
            font-size: 28px;
            font-weight: 500;
            color: #666666;
            line-height: 90px;
        }
        .department-item {
            height: 90px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 2px solid  #EDEDED;;
            .item-name {
                font-size: 32px;
                font-weight: 600;
                color: #333333;
            }
            .item-phone {
                display: flex;
                justify-content: space-between;
                // align-items: center;
                .phone {
                    font-size: 28px;
                    font-weight: 400;
                    color: #999999;
                    margin-right: 40px;
                }
                .phone-icon {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
    .bg{
      width:750px;
      height:602px;
    }
</style>